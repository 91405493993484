// @flow
import * as React from 'react'
import styled from 'styled-components'
import Modal from 'react-responsive-modal'
import { rem } from 'polished'

import { breakpoints } from '../../styles/media'
import MediaQuery from '../MediaQuery/MediaQuery'
import RemoveIcon from '../../icons/RemoveIcon'

type Props = {
  children?: React.Node,
  open?: boolean,
  onClose?: () => void,
  id?: string,
}

type State = {}

const ModalContent = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

const ModalClose = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  svg {
    g {
      stroke: white;
    }
  }
`

class ImageFullScreenModal extends React.Component<Props, State> {
  static displayName = 'ImageFullscreen'
  static defaultProps = {}

  handleCloseModal = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  render() {
    const { children, id, open } = this.props

    return (
      <React.Fragment>
        <MediaQuery query={`(min-width: ${breakpoints.md}em)`}>
          <Modal
            open={open}
            showCloseIcon={false}
            closeOnEsc
            center
            focusTrapped
            onClose={this.handleCloseModal}
            overlayId={id}
            styles={{
              modal: {
                padding: '0',
                width: '80%',
                maxWidth: '80%',
              },
            }}
          >
            <ModalClose onClick={this.handleCloseModal}>
              <RemoveIcon style={{ height: rem(30), width: rem(30) }} />
            </ModalClose>
            <ModalContent>{children && children}</ModalContent>
          </Modal>
        </MediaQuery>
        <MediaQuery query={`(max-width: ${breakpoints.md}em)`}>
          <Modal
            open={open}
            showCloseIcon={false}
            closeOnEsc
            center
            overlayId={id}
            focusTrapped
            onClose={this.handleCloseModal}
            styles={{
              modal: {
                padding: '0',
                width: '80%',
                maxWidth: '80%',
              },
            }}
          >
            <ModalClose onClick={this.handleCloseModal}>
              <RemoveIcon style={{ height: rem(25), width: rem(25) }} />
            </ModalClose>
            <ModalContent>{children && children}</ModalContent>
          </Modal>
        </MediaQuery>
      </React.Fragment>
    )
  }
}

export default ImageFullScreenModal
