// @flow
import useId from '@charlietango/use-id'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { ImageViewModel } from '../../types/ImageViewModel'

import Image from '../Image/Image'
import ImageFullscreenModal from './ImageFullscreenModal'

type Props = ImageViewModel

const StyledButton = styled.button`
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  &:focus {
    border: 1px dashed currentColor;
  }
`

function ImageFullscreen(props: Props) {
  const id = useId('imageModal')
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleClick = () => {
    setOpenModal(true)
  }

  const handleKeyDown = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      setOpenModal(true)
    }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const { aspectRatio, ...fullscreenProps } = props

  return (
    <>
      <StyledButton
        onClick={handleClick}
        aria-label="vis billede i fuldskærm"
        aria-controls={id}
        onKeyDown={handleKeyDown}
      >
        <Image {...props} />
      </StyledButton>

      <ImageFullscreenModal id={id} open={openModal} onClose={handleClose}>
        <Image {...fullscreenProps} />
      </ImageFullscreenModal>
    </>
  )
}

export default ImageFullscreen
